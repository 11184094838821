@media screen and (max-width: @screen-md) {
  .@{list-prefix-cls} {
    &-item {
      &-action {
        margin-left: 24px;

        .@{list-prefix-cls}-rtl & {
          margin-right: 24px;
          margin-left: 0;
        }
      }
    }
  }

  .@{list-prefix-cls}-vertical {
    .@{list-prefix-cls}-item {
      &-extra {
        margin-left: 24px;

        .@{list-prefix-cls}-rtl & {
          margin-right: 24px;
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: @screen-sm) {
  .@{list-prefix-cls} {
    &-item {
      flex-wrap: wrap;
      &-action {
        margin-left: 12px;

        .@{list-prefix-cls}-rtl & {
          margin-right: 22px;
          margin-left: 0;
        }
      }
    }
  }

  .@{list-prefix-cls}-vertical {
    .@{list-prefix-cls}-item {
      flex-wrap: wrap-reverse;
      &-main {
        min-width: 220px;
      }
      &-extra {
        margin: auto auto 16px;

        // to override margins on rtl view
        .@{list-prefix-cls}-rtl& {
          margin: auto auto 16px;
        }
      }
    }
  }
}
