@import '../../style/themes/index';
@import '../../style/mixins/index';
@import '../../checkbox/style/mixin';
@import './customize.less';

@transfer-prefix-cls: ~'@{ant-prefix}-transfer';

@transfer-header-vertical-padding: (
    @transfer-header-height - 1px - @font-size-base * @line-height-base
  ) / 2;

.@{transfer-prefix-cls} {
  .reset-component;

  position: relative;

  &-rtl {
    direction: rtl;
  }

  &-disabled {
    .@{transfer-prefix-cls}-list {
      background: @transfer-disabled-bg;
    }
  }

  &-list {
    position: relative;
    display: inline-block;
    width: 180px;
    height: @transfer-list-height;
    padding-top: @transfer-header-height;
    vertical-align: middle;
    border: @border-width-base @border-style-base @border-color-base;
    border-radius: @border-radius-base;

    &-with-footer {
      padding-bottom: 34px;
    }

    &-search {
      padding-right: 24px;
      padding-left: @control-padding-horizontal-sm;
      &-action {
        position: absolute;
        top: 12px;
        right: 12px;
        bottom: 12px;
        width: 28px;
        color: @disabled-color;
        line-height: @input-height-base;
        text-align: center;

        .@{transfer-prefix-cls}-rtl & {
          right: auto;
          left: 12px;
        }

        .@{iconfont-css-prefix} {
          color: @disabled-color;
          transition: all 0.3s;
          &:hover {
            color: @text-color-secondary;
          }
        }
        span& {
          pointer-events: none;
        }
      }
    }

    &-header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      // border-top is on the transfer dom. We should minus 1px for this
      padding: (@transfer-header-vertical-padding - 1px) @control-padding-horizontal
        @transfer-header-vertical-padding;
      overflow: hidden;
      color: @text-color;
      background: @component-background;
      border-bottom: @border-width-base @border-style-base @border-color-split;
      border-radius: @border-radius-base @border-radius-base 0 0;

      .@{transfer-prefix-cls}-rtl & {
        right: 0;
        left: auto;
      }
      &-title {
        position: absolute;
        right: 12px;

        .@{transfer-prefix-cls}-rtl & {
          right: auto;
          left: 12px;
        }
      }

      .@{ant-prefix}-checkbox-wrapper + span {
        padding-left: 8px;

        .@{transfer-prefix-cls}-rtl & {
          padding-right: 8px;
          padding-left: 0;
        }
      }
    }

    &-body {
      position: relative;
      height: 100%;
      font-size: @font-size-base;

      &-search-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 12px;

        .@{transfer-prefix-cls}-rtl & {
          right: 0;
          left: auto;
        }
      }
    }

    &-body-with-search {
      padding-top: @input-height-base + 24px;
    }

    &-content {
      height: 100%;
      margin: 0;
      padding: 0;
      overflow: auto;
      list-style: none;
      &-item {
        min-height: 32px;
        padding: 6px @control-padding-horizontal;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        transition: all 0.3s;
        > span {
          padding-right: 0;

          .@{transfer-prefix-cls}-rtl & {
            padding-left: 0;
          }
        }
        &-text {
          padding-left: 8px;

          .@{transfer-prefix-cls}-rtl & {
            padding-right: 8px;
            padding-left: 0;
          }
        }
      }

      &-item:not(&-item-disabled):hover {
        background-color: @transfer-item-hover-bg;
        cursor: pointer;
      }

      &-item-checked {
        background-color: @item-active-bg;
      }

      &-item-disabled {
        color: @btn-disable-color;
        cursor: not-allowed;
      }
    }

    &-body-not-found {
      position: absolute;
      top: 50%;
      width: 100%;
      padding-top: 0;
      color: @disabled-color;
      text-align: center;
      transform: translateY(-50%);

      // with filter should offset the search box height
      .@{transfer-prefix-cls}-list-body-with-search & {
        margin-top: @input-height-base / 2;
      }
    }

    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-top: @border-width-base @border-style-base @border-color-split;
      border-radius: 0 0 @border-radius-base @border-radius-base;

      .@{transfer-prefix-cls}-rtl & {
        right: 0;
        left: auto;
      }
    }
  }

  &-operation {
    display: inline-block;
    margin: 0 8px;
    overflow: hidden;
    vertical-align: middle;

    .@{ant-prefix}-btn {
      display: block;

      &:first-child {
        margin-bottom: 4px;
      }

      .@{iconfont-css-prefix} {
        font-size: 12px;
      }
    }
  }
}
